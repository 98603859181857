export const element = document.getElementById('widget-integrations-search-bar');

export const {
  hash,
  redirect,
  slug,
  brokerage,
  corners = 4,
  selectColors,
  buttonColors,
  menuPlacement = 'bottom',
} = element ? element.dataset : {};

export const publicLicense = '12ajjdewwwy-26rnhw2943-1s7g1u8ma0i'; // public license, GPLv3 - for open-source
