import React, { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import isEmpty from 'lodash/isEmpty';

import Button from './Button';

import loadOptions from '../fetchers';
import themify, { styles, components, menuPlacement } from '../themes';
import { brokerage } from '../settings';

const brokerageSubdomain = brokerage ? `${brokerage}.` : '';

const getSmartyDetailedSuggestions = async (generalizedSmartySuggestions) => {
  const smartyDetailedUrl = new URL(`https://${brokerageSubdomain}ruuster.com/api/autocomplete/smarty-detailed`);
  smartyDetailedUrl.searchParams.set('suggestions', JSON.stringify(generalizedSmartySuggestions));

  const response = await fetch(smartyDetailedUrl);
  const result = await response.json();
  return result;
};

const getArrWithReplacedItems = (initialArr, detailedArray) => {
  return initialArr.map((op1) => {
    const itemToReplaceWith = detailedArray
      .find((op2) => op1?.data?.addressId === op2?.data?.addressId);
    return itemToReplaceWith || op1;
  });
}

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [supportedMlsStates, setSupportedMlsStates] = useState([]);
  const [includeSubdivisions, setIncludeSubdivisions] = useState(false);
  const [isUSBasedBrokerage, setIsUSBasedBrokerage] = useState();
  const [isCanadaBasedBrokerage, setIsCanadaBasedBrokerage] = useState();
  const [brokerageId, setBrokerageId] = useState();

  const onAddressChange = async (data) => {
    let optionsToSet = data;

    if (isCanadaBasedBrokerage) {
      // replace generalized suggestions with detailed suggestions (if was already fetched)
      optionsToSet = getArrWithReplacedItems(optionsToSet, options);

      const generalizedSmartySuggestions = optionsToSet
        .filter((option) => option?.data?.addressText);

      // replace generalized suggestions with detailed suggestions (if such exists)
      if (!isEmpty(generalizedSmartySuggestions)) {
        const detailedSuggestions = await getSmartyDetailedSuggestions(
          generalizedSmartySuggestions,
        );

        optionsToSet = getArrWithReplacedItems(optionsToSet, detailedSuggestions);
      }
    }

    setOptions(optionsToSet);
  };

  const patchedLoadOptions = useCallback(
    (search, callback) => loadOptions({
      search,
      callback,
      supportedMlsStates,
      includeSubdivisions,
      isUSBased: isUSBasedBrokerage,
      isCanadaBased: isCanadaBasedBrokerage,
      brokerageId,
    }),
    [supportedMlsStates, includeSubdivisions, isUSBasedBrokerage, isCanadaBasedBrokerage, brokerageId],
  );

  const supportedStatesFetcher = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(`https://${brokerageSubdomain}ruuster.com/api/brokerage/${brokerage}/search-data`);

      if (response && response.ok) {
        const result = await response.json();

        if (result && result.supportedMlsStates) {
          setSupportedMlsStates(result.supportedMlsStates);
        }

        if (result && result.includeSubdivisions) {
          setIncludeSubdivisions(result.includeSubdivisions);
        }

        setIsUSBasedBrokerage(result?.isUSBased);
        setIsCanadaBasedBrokerage(result?.isCanadaBased);
        setBrokerageId(result?.brokerageId);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    supportedStatesFetcher();
  }, []);

  return (
    <div
      className='rwsb-wrapper'
    >
      <AsyncSelect
        loadOptions={patchedLoadOptions}
        onChange={onAddressChange}
        theme={themify}
        styles={styles}
        components={components}
        placeholder="City, ZIP, Neighborhood, School"
        isClearable
        isMulti
        isLoading={isLoading}
        openMenuOnFocus={false}
        openMenuOnClick={false}
        menuPlacement={menuPlacement}
      />
      <Button
        options={options}
      />
    </div>
  );
};

export default Main;
