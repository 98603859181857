import debounce from 'lodash/debounce';
import isBoolean from 'lodash/isBoolean';

import { hash, redirect, slug, brokerage } from './settings';

const labelsMapper = {
  googleAutocomplete: 'City, Postal Code',
  smartyStreets: 'Address',
  neighborhood: 'Neighborhood',
  county: 'County',
  school: 'School',
  subdivision: 'Subdivision',
};

const brokerageSubdomain = brokerage ? `${brokerage}.` : '';

const loadOptions = debounce(({
  search,
  callback,
  supportedMlsStates,
  includeSubdivisions,
  isUSBased,
  isCanadaBased,
  brokerageId,
}) => {
  const createdUrl = new URL(`https://${brokerageSubdomain}ruuster.com/api/autocomplete`);

  createdUrl.searchParams.set('types', 'address');
  createdUrl.searchParams.set('hash', hash);
  createdUrl.searchParams.set('redirect', redirect);
  createdUrl.searchParams.set('agentSlug', slug);
  createdUrl.searchParams.set('search', search);

  if (supportedMlsStates) {
    createdUrl.searchParams.set('supportedMlsStates', supportedMlsStates);
  }

  if (includeSubdivisions) {
    createdUrl.searchParams.set('includeSubdivisions', includeSubdivisions);
  }

  if (isBoolean(isUSBased)) {
    createdUrl.searchParams.set('isUSBased', isUSBased);
  }

  if (isBoolean(isCanadaBased)) {
    createdUrl.searchParams.set('isCanadaBased', isCanadaBased);
  }

  if (brokerageId) {
    createdUrl.searchParams.set('brokerageId', brokerageId);
  }

  fetch(createdUrl).then(async (res) => {
    const { suggestions } = await res.json();
    const groups = Object.groupBy(suggestions, ({ source }) => source);
    const groupKeys = Object.keys(groups);
    const groupedOptions = groupKeys.map((key) => ({
      label: labelsMapper[key],
      options: groups[key],
    }));

    callback(groupedOptions);
  });
}, 800);

export default loadOptions;
