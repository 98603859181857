import {
  selectColors,
  buttonColors,
  corners,
  menuPlacement as menuPlacementSetting,
} from './settings';

const menuPlacementValidValues = ['bottom', 'auto', 'top'];

const defaultSelectColors = ['#fff', '#e0eafd', '#000', '#000', '#ccc', '#ccc'];
const defaultButtonColors = ['#e0eafd', '#2860f6', '#2860f6', '#fff'];

const selectSplittedColors = selectColors && selectColors.split(',');
const buttonSplittedColors = buttonColors && buttonColors.split(',');

const resultSelectColors = selectSplittedColors && selectSplittedColors.length === 6
  ? selectSplittedColors
  : defaultSelectColors;

const resultButtonColors = buttonSplittedColors && buttonSplittedColors.length === 4
  ? buttonSplittedColors
  : defaultButtonColors;

export const theme = {
  select: {
    background: resultSelectColors[0],
    backgroundHover: resultSelectColors[1],
    textColor: resultSelectColors[2],
    textColorHover: resultSelectColors[3],
    borderColor: resultSelectColors[4],
    borderColorHover: resultSelectColors[5],
  },
  button: {
    background: resultButtonColors[0],
    backgroundHover: resultButtonColors[1],
    textColor: resultButtonColors[2],
    textColorHover: resultButtonColors[3],
  },
}

export const styles = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  valueContainer: (base) => ({
    ...base,
    height: '50px',
  }),
  multiValue: (base) => ({
    ...base,
    color: theme.select.textColorHover,
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.select.textColorHover,
  }),
  input: (base) => ({
    ...base,
    fontSize: '17px',
  }),
  option: (base, { isFocused }) => ({
    ...base,
    background: isFocused ? theme.select.backgroundHover: theme.select.background,
    color: isFocused ? theme.select.textColorHover : theme.select.textColor,
  }),
};

export const components = {
  DropdownIndicator: () => {},
  IndicatorSeparator: () => {},
};

export const menuPlacement = menuPlacementValidValues
  .find((v) => v === menuPlacementSetting) || 'bottom';

const themify = (t) => {
  return {
    ...t,
    borderRadius: +corners,
    colors: {
      ...t.colors,
      ...{
        primary: theme.select.borderColor, // active border
        primary25: theme.select.background, // item background
        neutral0: theme.select.background, // background
        neutral10: theme.select.backgroundHover, // chips background
        neutral20: theme.select.borderColor, // border
        neutral30: theme.select.borderColorHover, // hover border
        neutral40: theme.select.textColor, // Loading and No Options text color
        neutral50: theme.select.textColor, // Select... text color
        neutral80: theme.select.textColor, // main text color
      },
    },
  };
};

export default themify;
