import { publicLicense } from './settings';
import convertToBoolean from '../../../helpers/convertToBoolean';

document.addEventListener('DOMContentLoaded', () => {
  const iframeWrapperById = document.getElementById('widget-integrations-listings-search');
  const iframeWrappers = document.getElementsByClassName('widget-integrations-listings-search');
  const nodes = [iframeWrapperById, ...iframeWrappers].filter(Boolean);

  if (!nodes.length) return;

  nodes.forEach(iframeWrapper => {
    const {
      url,
      hideBackground,
      hideScroll,
      hideSidebar,
      hideSearchbar,
      limits,
      nextButtonPosition = 'left',
      newTab,
      listingsPerPage,
      limit,
      forceRegistration,
      hideButtons,
      customCrmTags,
      iframeTopMargin,
    } = iframeWrapper.dataset;
    if (iframeWrapper && url) {
      const parentUrl = window.location.href;
      const parsedParentUrl = new URL(parentUrl);
      const addresses = parsedParentUrl.searchParams.getAll('address');
      const parsedChildrenUrl = new URL(addresses && addresses.length ? url.split('?')[0] : url);

      if (addresses && addresses.length) {
        addresses.forEach((address, i) => {
          if (i === 0) {
            parsedChildrenUrl.searchParams.set('address', address);
          } else {
            parsedChildrenUrl.searchParams.append('address', address);
          }
        });
      }

      if (hideBackground) {
        parsedChildrenUrl.searchParams.set('hideBackground', convertToBoolean(hideBackground));
      }

      if (hideScroll) {
        parsedChildrenUrl.searchParams.set('hideScroll', convertToBoolean(hideScroll));
      }

      if (hideSidebar) {
        parsedChildrenUrl.searchParams.set('hideSidebar', true);
      }

      if (hideSearchbar) {
        parsedChildrenUrl.searchParams.set('hideSearchbar', hideSearchbar);
      }

      if (limits) {
        parsedChildrenUrl.searchParams.set('listingsPerPage', limits);
      }

      if (nextButtonPosition) {
        parsedChildrenUrl.searchParams.set('nextButtonPosition', nextButtonPosition);
      }

      if (newTab) {
        parsedChildrenUrl.searchParams.set('newTabPropertyView', newTab);
      }

      if (limit) {
        parsedChildrenUrl.searchParams.set('limit', limit);
      }

      if (listingsPerPage) {
        parsedChildrenUrl.searchParams.set('listingsPerPage', listingsPerPage);
      }

      if (hideButtons) {
        parsedChildrenUrl.searchParams.set('hideButtons', hideButtons);
      }

      if (forceRegistration) {
        parsedChildrenUrl.searchParams.set('forceRegistration', forceRegistration);
      }

      if (customCrmTags) {
        parsedChildrenUrl.searchParams.set('customCrmTags', customCrmTags);
      }

      if (iframeTopMargin) {
        parsedChildrenUrl.searchParams.set('iframeTopMargin', iframeTopMargin);
      }

      const stringUrl = parsedChildrenUrl.toString();
      const iframe = document.createElement("iframe");
      const loader = document.createElement("div");
      iframe.setAttribute('class', 'integraion-iframe-element');
      iframe.setAttribute('class', 'rwsb-iframe');
      loader.setAttribute('class', 'loader');

      iframe.src = stringUrl;
      iframe.style.display = 'block';

      iframeWrapper.appendChild(loader);
      iframeWrapper.appendChild(iframe);

      iframeResize({
        license: publicLicense,
        waitForLoad: true,
        onResized: ({ iframe }) => {
          iframeWrapper.classList.add('loaded');
        },
      }, iframe);
    }
  });
});
