import iframeResize from '@iframe-resizer/parent';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { element, publicLicense } from './settings';

import Main from './components/Main';

import './styles/integration.css';
import './iframe';

window.iframeResize = iframeResize;

iframeResize({
  license: publicLicense,
  waitForLoad: true,
}, '#listing-integrations')

iframeResize({
  license: publicLicense,
  waitForLoad: true,
}, '.listing-integrations')

if (element && element.dataset) {
  ReactDOM.createRoot(element).render(
    <Main />
  );
}
