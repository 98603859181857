import React, { useState } from 'react';

import { redirect, corners } from '../settings';
import { theme } from '../themes';

const Button = ({ options }) => {
  const [ isHover, setIsHover ] = useState(false);
  const url = new URL(redirect);

  options && options.forEach((option) => {
    const stringifiedOption = JSON.stringify(option);

    url.searchParams.append('address', stringifiedOption);
  });

  return (
    <button
      type="button"
      className='rwsb-wrapper-button'
      style={{
        border: 'none',
        outline: 'none',
        boxSizing: 'border-box',
        borderRadius: `${corners}px`,
        fontSize: '14px',
        backgroundColor: isHover ? theme.button.backgroundHover : theme.button.background,
        color: isHover ? theme.button.textColorHover : theme.button.textColor,
        cursor: 'pointer',
      }}
      onClick={() => { if (options && options.length) window.location.href = url }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      Search
    </button>
  );
};

export default Button;
